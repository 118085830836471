import React, { useRef } from "react";
import { motion } from "framer-motion";

const ProjectList = () => {
  const projectRefs = useRef([]);

  const professionalProjects = [
    { number: "01", title: "Speech Recognition", ref: "As a Freelancer", link: "https://speech-recognition.hugoquintal.pt/" },
    { number: "02", title: "Hugo Quintal - Landing Page", ref: "As a Freelancer", link: "https://criacao-websites.hugoquintal.pt/" },
    { number: "03", title: "Nicola Rossio Hotel", ref: "At Sanzza", link: "https://nicolarossiohotel.com/" },
    { number: "04", title: "Rossio Plaza Hotel", ref: "At Sanzza", link: "https://rossioplazahotel.com/" },
    { number: "05", title: "Avô Moleiro", ref: "At Sanzza", link: "https://avomoleiro.pt/" },
    { number: "06", title: "Shift Global", ref: "At Sanzza", link: "https://www.shift-global.com/" },
    { number: "07", title: "Gestout", ref: "At Sanzza", link: "https://www.gestout.pt/" },
    { number: "08", title: "Hospital Santa Maria Porto", ref: "At Sanzza", link: "https://hsmporto.pt/" },
    { number: "09", title: "The Ocean Living", ref: "At Sanzza", link: "https://theoceanliving.pt/" },
    { number: "10", title: "Going Up", ref: "At Sanzza", link: "https://goingup.pt/" }
  ];

  const githubProjects = [
    { number: "11", title: "Real State App", link: "https://hugoquintal01.github.io/real-estate/" },
    { number: "12", title: "Product React App", link: "https://hugoquintal01.github.io/product-react-app/" },
    { number: "13", title: "Login Use Case", link: "https://hugoquintal01.github.io/login-use-case/" },
    { number: "14", title: "Auth React App", link: "https://hugoquintal01.github.io/auth-react-app/" },
    { number: "15", title: "Todo App", link: "https://hugoquintal01.github.io/todo-app/" },
    { number: "16", title: "Tic-tac-toe App", link: "https://hugoquintal01.github.io/tic-tac-toe/" },
    { number: "17", title: "Quiz App", link: "https://hugoquintal01.github.io/quiz-app/" }
  ];

  return (
    <section className="project-list-section gridrowfull">
      <div className="project-list-container col-12">
        {professionalProjects.map((project, index) => (
          <motion.a
            key={project.number}
            href={project.link}
            className="project-item col-6 col-t-6 col-d-3"
            target="_blank"
            rel="noopener noreferrer"
            initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.4, delay: index * 0.1 }}
            ref={(el) => (projectRefs.current[index] = el)}
          >
            <div className="project-number">
              <span>{project.number}</span>
            </div>
            <div className="project-title">
              <span>{project.title}</span>
              <span className="project-ref">({project.ref})</span>
            </div>
          </motion.a>
        ))}
        <motion.div
          className="sub-title col-12"
          initial={{ opacity: 0, y: 50 }}
          whileInView={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.4, delay: professionalProjects.length * 0.1 }}
          ref={(el) => (projectRefs.current[professionalProjects.length] = el)}
        >
          <h3>Check my github projects</h3>
          <a href="https://github.com/HugoQuintal01" target="_blank" rel="noopener noreferrer">Github Page</a>
        </motion.div>
        {githubProjects.map((project, index) => (
          <motion.a
            key={project.number}
            href={project.link}
            className="project-item col-12 col-t-6 col-d-3"
            target="_blank"
            rel="noopener noreferrer"
            initial={{ opacity: 0, y: 50 }}
            whileInView={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.4, delay: (professionalProjects.length + index + 1) * 0.1 }}
            ref={(el) => (projectRefs.current[professionalProjects.length + index + 1] = el)}
          >
            <div className="project-number">
              <span>{project.number}</span>
            </div>
            <div className="project-title">
              <span>{project.title}</span>
            </div>
          </motion.a>
        ))}
      </div>
    </section>
  );
};

export default ProjectList;